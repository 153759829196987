import { deepClone } from '../../../common/helpers';
import moment from 'moment';
import { defaultOption, otherOption } from '../components/InvoicingDelivery';

export const impressionMethod = 'impression';

export const scanMethod = 'scan';

export const emptyOrder = {
    provideTreatmentPlan: '',
    prescriberName: '',
    patientName: '',
    instructions: '',
    treatmentType: '',
    arches: 'Upper and lower',
    allowIPR: '',
    allowAttachment: '',
    upperMidline: '',
    lowerMidline: '',
    posteriorCrossbite: '',
    anteriorPosteriorRelation: '',
    trimLine: 'Straight cut at the gingival zenith margin',
    retainersRequired: '',
    dentalMonitoring: '',
    serialNumber: moment().format('YYMMDD-HHmmss'),
    method: '',
    invoicingOption: defaultOption,
    invoicingRecipient: '',
    deliveryOption: defaultOption,
    deliveryStreetAddress: '',
    deliverySuburb: '',
    deliveryPostcode: '',
    deliveryState: '',
};

export const defaultFormStepsTitle = [
    'Treatment Plan',
    'Case Attributes',
    'Case Design',
    '3D Scan / Impression',
    'Files Attachment',
    'Invoicing & Delivery',
    'Order Confirmation',
];

export const refinementFormStepsTitle = [
    'Case Design',
    '3D Scan / Impression',
    'Files Attachment',
    'Order Confirmation',
];

// ======================== Design Attributes ============================

const designAttributes = [
    {
        id: 'treatmentType',
        label: 'Treatment Type',
        items: [
            '3 to 3 (Canine to Canine)',
            '5 to 5 (2nd Premolar to 2nd Premolar)',
            '7 to 7 (2nd Molar to 2nd Molar)',
        ],
        required: true,
        scope: ['dental'],
    },
    {
        id: 'arches',
        label: 'Arches Selection',
        items: ['Upper and lower', 'Upper only', 'Lower only'],
        required: true,
        scope: ['dental', 'ortho'],
    },
    {
        id: 'allowIPR',
        label: 'Allow IPR',
        items: [
            'No IPR',
            'Yes, First Expansion and Proclination then IPR',
            'Yes, First IPR, try to avoid Expansion and Proclination',
        ],
        required: true,
        scope: ['dental'],
    },
    {
        id: 'allowAttachment',
        label: 'Allow Attachment',
        items: ['Yes', 'No'],
        required: true,
        scope: ['dental'],
    },
    {
        id: 'upperMidline',
        label: 'Upper Midline',
        items: ['Maintain', "Move to patient's left", "Move to patient's right", 'Improve'],
        required: true,
        scope: ['dental'],
    },
    {
        id: 'lowerMidline',
        label: 'Lower Midline',
        items: ['Maintain', "Move to patient's left", "Move to patient's right", 'Improve'],
        required: true,
        scope: ['dental'],
    },
    {
        id: 'posteriorCrossbite',
        label: 'Posterior Crossbite Handling',
        items: ['Maintain Posterior Crossbite', 'Correct Premolars', 'Correct Molars', 'N/A'],
        scope: ['dental', '7to7-treatment'],
    },
    {
        id: 'anteriorPosteriorRelation',
        label: 'Anterior Posterior Relation',
        items: [
            'Maintain Anterior Posterior Relation',
            'Improve Canine Relationship',
            'Improve Molar Relationship',
            'N/A',
        ],
        scope: ['dental', '7to7-treatment'],
    },
    {
        id: 'trimLine',
        label: 'Trim Line',
        items: ['Straight cut at the gingival zenith margin', 'Scalloped margin'],
        scope: ['dental', 'ortho'],
    },
    {
        id: 'retainersRequired',
        label: 'Retainers required (Free of charge)',
        items: [
            { value: 'Yes', displayValue: 'Yes (Order retainers with aligners)' },
            { value: 'No', displayValue: 'No (Order retainers on treatment completion)' },
        ],
        required: true,
        scope: ['dental', 'ortho'],
    },
    {
        id: 'dentalMonitoring',
        label: 'AI Dental Monitoring',
        items: ['Yes', 'No'],
        required: true,
        scope: ['dental', 'ortho'],
        helperText: [
            'Cost: $199+ɢsᴛ (Includes ScanBox',
            <sup key={'ScanBoxPro superscript'}>Pro</sup>,
            ' and concierge monitoring service by Smile Academy)',
        ],
    },
];

/**
 * Restrict treatment type input field for refinement order. We can't downgrade a treatment type
 * @param {*} allAttributes a copy of all the design attributes
 * @param {*} order the current order being created
 * @param {*} allowFullArchTreatment does the practice allow full arch treatment
 */
const getDesignAttributesForRefinementOrder = (allAttributes, order, allowFullArchTreatment) => {
    // remove modifiers specific to full arch treatment if the treatment type is not full arch
    const isFullArchTreatment = order.treatmentType.match('7 to 7');
    let attributes = isFullArchTreatment
        ? allAttributes
        : allAttributes.filter((attr) => !attr.scope.includes('7to7-treatment'));

    attributes.forEach((attr) => {
        if (attr.id === 'treatmentType') {
            // only keep current treatment type and above
            if (order.treatmentType.match('5 to 5')) {
                // remove first element
                attr.items.shift();
            } else if (order.treatmentType.match('7 to 7')) {
                // only keep the last item
                attr.items = attr.items.slice(2);
            }

            // remove 7 to 7 treatment option if the practice cannot do full arch treatment
            if (!allowFullArchTreatment) {
                attr.items = attr.items.filter((item) => !item.match('7 to 7'));
            }
        }
    });

    // remove Dental Monitoring attribute for refinement orders
    attributes = attributes.filter((attr) => attr.id !== 'dentalMonitoring');

    return attributes;
};

/**
 * Returns the relevant design attributes based on provided treatment plan and full arch treatment capability
 * @param {*} allAttributes a copy of all the design attributes
 * @param {*} order the current order being created
 * @param {*} allowFullArchTreatment does the practice allow full arch treatment
 */
const getDesignAttributesForOrder = (allAttributes, order, allowFullArchTreatment) => {
    // remove modifiers specific to orthodontia if the treatment is not provided by the practice but by Smile Academy
    const scope = order.provideTreatmentPlan === 'Yes' ? 'ortho' : 'dental';
    let attributes = allAttributes.filter((attr) => attr.scope.includes(scope));

    // remove 7 to 7 treatment option if the practice cannot do full arch treatment
    if (!allowFullArchTreatment) {
        attributes = attributes.map((attr) => {
            if (attr.id === 'treatmentType' && attr.items.length === 3) attr.items.pop();
            return attr;
        });
    }

    // remove modifiers specific to full arch treatment if the treatment type is not full arch
    const isFullArchTreatment = order.treatmentType.match('7 to 7');
    if (!isFullArchTreatment) {
        attributes = attributes.filter((attr) => !attr.scope.includes('7to7-treatment'));
    }

    return attributes;
};

/**
 * Returns the input fields for the Case Design form
 * Filter based on whether the case is for a dentist or an ortho dentist
 * Display full arch treatment (7 to 7) only for allowed practices
 */
export const caseDesignAttributes = (order, allowFullArchTreatment = false) => {
    // clone with safely copies deeply nested objects/arrays
    const attributesCopy = deepClone(designAttributes);

    return order.refinement === 'Yes'
        ? getDesignAttributesForRefinementOrder(attributesCopy, order, allowFullArchTreatment)
        : getDesignAttributesForOrder(attributesCopy, order, allowFullArchTreatment);
};

// ======================== Invoicing & Delivery ============================

export const invoicingRecipientAttributes = [
    {
        id: 'invoicingRecipient',
        label: 'Practice Name',
        required: true,
    },
];

export const deliveryAddressAttributes = [
    {
        id: 'deliveryStreetAddress',
        label: 'Street Address',
        required: true,
    },
    {
        id: 'deliverySuburb',
        label: 'Suburb',
        required: true,
    },
    {
        id: 'deliveryPostcode',
        label: 'Postcode',
        type: 'number',
        required: true,
    },
    {
        id: 'deliveryState',
        label: 'State/Country',
        required: true,
        items: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'NZ'],
    },
];

// ======================== Order details ============================

/**
 * Hide these properties in the order details screen
 */
export const excludedProperties = ['provideTreatmentPlan', 'filename'];

// ======================== Helpers ============================

/**
 * Clears N/A values from the form values
 */
export const sanitizeFormEntry = (formValues, [key, value]) => {
    formValues[key] = value === 'N/A' ? '' : value;
    return formValues;
};

/**
 * Returns true if the the error fields contains errors
 */
export const hasMissingRequiredFields = (errorFields) => Object.values(errorFields).filter((x) => !!x).length !== 0;

/**
 * Finds the required fields that are missing
 */
export const findMissingRequiredFields = (formAttributes, order) => {
    const requiredFormAttributes = formAttributes.filter((el) => el.required);
    const requiredFieldErrors = requiredFormAttributes.reduce((errors, attr) => {
        errors[attr.id] = !order[attr.id];
        return errors;
    }, {});
    return requiredFieldErrors;
};

export const formatInvoicingAndDelivery = (order, user) => {
    const orderCopy = { ...order };
    const isNewOrder = !order.orderId;
    const { deliveryStreetAddress, deliverySuburb, deliveryPostcode, deliveryState } = orderCopy;

    // invoicing
    if (orderCopy.invoicingOption === defaultOption && isNewOrder) {
        orderCopy.invoicingRecipient = user?.attributes?.name;
    }
    delete orderCopy.invoicingOption;

    // delivery
    if (orderCopy.deliveryOption === defaultOption && isNewOrder) {
        orderCopy.deliveryAddress = user?.practice?.address || `${user?.attributes?.name} address`;
    } else if (orderCopy.deliveryOption === otherOption || isNewOrder) {
        orderCopy.deliveryAddress = `${deliveryStreetAddress}, ${deliverySuburb} ${deliveryPostcode} ${deliveryState}`;
    }

    ['deliveryOption', 'deliveryStreetAddress', 'deliverySuburb', 'deliveryPostcode', 'deliveryState'].forEach(
        (attr) => delete orderCopy[attr]
    );

    return orderCopy;
};
